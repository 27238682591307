import * as AWS from "aws-sdk"
import { telegram } from "../utils/telegram"

async function convertToJPG(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0)
        canvas.toBlob(
          (blob) => {
            resolve(blob)
          },
          "image/jpeg",
          0.95
        )
      }
      img.src = event.target.result
    }
    reader.onerror = (error) => {
      reject(error)
      telegram("frontend, convertToJPG: " + error.message)
    }
    reader.readAsDataURL(file)
  })
}

export async function uploadToR2(file, convertToJPGOption = false) {
  try {
    const accessKeyId = `3736df7c42164f7c799db4603ac8848f`
    const secretAccessKey = `798616681ca25c9d3ab935e957566b7c90ba1d363aebd2765fdf262d5f157861`
    const endpoint =
      "https://d181f16c5eadd7fb49486c2652a0f065.r2.cloudflarestorage.com"
    const bucketName = "clarity"

    const s3 = new AWS.S3({
      region: "auto",
      signatureVersion: "v4",
      credentials: new AWS.Credentials(accessKeyId, secretAccessKey),
      endpoint: new AWS.Endpoint(endpoint),
    })
    const uuid = require("uuid")
    let filename = `inputs/${uuid.v4()}-${file.name}`
    let fileBuffer = file

    if (convertToJPGOption) {
      // Convert the file to a JPG image
      const jpgBuffer = await convertToJPG(file)
      fileBuffer = jpgBuffer
      filename = filename.replace(/\.[^/.]+$/, ".jpg") // Change the file extension to .jpg
    }

    const params = {
      Bucket: bucketName,
      Key: filename,
      Body: fileBuffer,
      ContentType: convertToJPGOption ? "image/jpeg" : file.type,
      ACL: "public-read",
    }

    await s3.upload(params).promise()
    const link = "https://r2.clarityai.co/" + filename
    return link
  } catch (error) {
    telegram("frontend, uploadToR2: " + error.message)
    throw error
  }
}
