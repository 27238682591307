export function Image({ image }) {
  return (
    <div className="flex w-full">
      <img
        alt="display"
        src={image?.outputImage}
        className="w-full rounded-lg"
      />
    </div>
  )
}
