import { useEffect } from "react"
import { useAuth } from "../../context/AuthContext"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"

export default function SuccessPage() {
  const { user } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (user) {
      const timer = setTimeout(() => {
        history.push("/dashboard")
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [user, history])

  return (
    <div className="w-full h-full flex flex-col items-center justify-center bg-bg-color text-white">
      {user && (
        <>
          <p className="font-bold mt-6 mb-2">
            Thank you for choosing Clarity AI
          </p>
          <Link to="/dashboard" className="text-blue-500">
            Go to Dashboard
          </Link>
        </>
      )}
    </div>
  )
}
