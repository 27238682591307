import React, { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import Upscale from "./Upscale"
import StyleImage from "./StyleImage"
import StyleVideo from "./StyleVideo"
import Pattern from "./Pattern"
import Video2Sound from "./Video2Sound"
import Create from "./Create"

const SubmitButton = ({
  isLoading,
  user,
  submitButtonClicked,
  setShowPopup,
  mode,
}) => {
  return (
    <div className="md:border-t px-3 md:py-3 border-neutral-700">
      <button
        type="submit"
        disabled={isLoading}
        onClick={user ? submitButtonClicked : () => setShowPopup(true)}
        className="w-full h-12 rounded-lg font-bold text-white bg-gradient-to-r from-cyan-500 to-blue-500 via-purple-500 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate"
      >
        <div className="flex flex-row place-content-center">
          {isLoading ? (
            <div className="flex justify-center items-center">
              <p className="pr-2">Loading</p>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-white animate-spin fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : (
            <span>
              {mode === "upscale"
                ? "Upscale"
                : mode === "style-transfer"
                ? "Transform"
                : mode === "video-transfer"
                ? "Transform"
                : mode === "pattern-create"
                ? "Create"
                : mode === "video-to-sound"
                ? "Transform"
                : mode === "create"
                ? "Create"
                : null}{" "}
              with Clarity AI
            </span>
          )}
        </div>
      </button>
    </div>
  )
}

export default function Options({ mode, setShowPopup }) {
  const { user } = useAuth()
  const [isLoading, setisLoading] = useState(false)
  const [createImageFunction, setCreateImageFunction] = useState(null)
  const [creditCost, setCreditCost] = useState(false)
  const [styleMode, setStyleMode] = useState("style-image")

  const validPlans = [
    "pro_19usd",
    "pro_29usd",
    "premium_49usd",
    "premium_74usd",
    "business",
    "admin",
  ]

  const submitButtonClicked = async () => {
    setisLoading(true)
    if (createImageFunction) {
      await createImageFunction()
    }

    setisLoading(false)
  }

  return (
    <div className="md:w-96 w-full h-full md:h-screen-16 md:overflow-y-auto md:max-w-xs md:border-r md:border-l border-neutral-700">
      {user && user.balance <= 100 && validPlans.includes(user.plan) && (
        <div>
          <p className="font-bold mt-6 mb-2">
            Add more tokens to your account with a one-time payment:
          </p>
          <div className="flex justify-center items-center mt-6">
            <stripe-buy-button
              className="align-middle"
              buy-button-id="buy_btn_1PFv9gKhV52MrjY6FMsAivnE"
              customer-email={user?.email}
              publishable-key="pk_live_51OP3HQKhV52MrjY6hU7IUmV9HRYKnp8QBgA41f7E2BzO9yFEJSOQ3QenB8bHApfVoHJFj6iryrnalopslAxriOE20017CS8uxp"
            ></stripe-buy-button>
          </div>
        </div>
      )}
      {mode === "upscale" && (
        <Upscale
          setCreateImageFunction={setCreateImageFunction}
          creditCost={creditCost}
          setCreditCost={setCreditCost}
        />
      )}
      {mode === "style-transfer" && (
        <>
          <div className="flex h-10 justify-center mx-3 my-2 py-1 bg-neutral-800 border border-neutral-700 rounded-lg">
            <button
              onClick={() => setStyleMode("style-image")}
              className={`w-1/2 ml-2 mr-1 transition-colors duration-300 rounded-lg ease-in-out ${
                styleMode === "style-image"
                  ? "bg-blue-600 text-white"
                  : "text-neutral-300 hover:bg-neutral-700 hover:text-white"
              }`}
            >
              Image
            </button>
            <button
              onClick={() => setStyleMode("style-video")}
              className={`w-1/2 ml-1 mr-2 transition-colors duration-300 rounded-lg ease-in-out ${
                styleMode === "style-video"
                  ? "bg-blue-600 text-white"
                  : "text-neutral-300 hover:bg-neutral-700 hover:text-white"
              }`}
            >
              Video
            </button>
          </div>
          {styleMode === "style-image" ? (
            <StyleImage
              setCreateImageFunction={setCreateImageFunction}
              creditCost={creditCost}
              setCreditCost={setCreditCost}
            />
          ) : styleMode === "style-video" ? (
            <StyleVideo
              setCreateImageFunction={setCreateImageFunction}
              creditCost={creditCost}
              setCreditCost={setCreditCost}
            />
          ) : null}
        </>
      )}
      {mode === "pattern-create" && (
        <Pattern
          setCreateImageFunction={setCreateImageFunction}
          creditCost={creditCost}
          setCreditCost={setCreditCost}
        />
      )}
      {mode === "video-to-sound" && (
        <Video2Sound
          setCreateImageFunction={setCreateImageFunction}
          creditCost={creditCost}
          setCreditCost={setCreditCost}
        />
      )}
      {mode === "create" && (
        <Create
          setCreateImageFunction={setCreateImageFunction}
          creditCost={creditCost}
          setCreditCost={setCreditCost}
        />
      )}
      <SubmitButton
        isLoading={isLoading}
        user={user}
        submitButtonClicked={submitButtonClicked}
        setShowPopup={setShowPopup}
        mode={mode}
      />
    </div>
  )
}
