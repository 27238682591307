import React from "react"
import "./assets/App.css"
import Dashboard from "./pages/Dashboard/Dashboard"
import Header from "./pages/Landingpage/Header"
import LandingHero from "./pages/Landingpage/Banner"
import Register from "./pages/Sign/Register"
import Login from "./pages/Sign/Login"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import ForgotPassword from "./pages/Sign/ForgotPassword"
import { AuthProvider } from "./context/AuthContext"
import PrivateRoute from "./components/PrivateRoute"
import Pricing from "./components/Pricing"
import Examples from "./pages/Landingpage/Examples"
import CalltoAction from "./pages/Landingpage/CalltoAction"
import Popup from "./pages/Sign/Popup"
import FAQs from "./components/Faq"
import Docs from "./pages/API/Docs"
import ComfyUI from "./pages/API/ComfyUI"
import Admin from "./pages/Dashboard/Admin"
import Success from "./pages/Dashboard/Success"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Testimonials from "./pages/Landingpage/Testimonials"
import Features from "./pages/Landingpage/Features"
import Footer from "./pages/Landingpage/Footer"

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <BrowserRouter>
          <div className="flex flex-col min-h-screen bg-bg-color">
            <Header />
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/">
                <Helmet>
                  <title>
                    Clarity AI - #1 AI Image Upscaler & Enhancer - Magnific
                    Alternative
                  </title>
                  <meta
                    name="description"
                    content="Upscale and enhance your images with the power of AI. Clarity AI is the #1 AI Image Upscaler & Enhancer with API support. Enlarge images and get HD quality. Magnific alternative."
                  />
                  <link rel="canonical" href="https://clarityai.co/" />
                </Helmet>
                <Popup />
                <LandingHero />
                <Features />
                <Examples />
                <FAQs />
                <Testimonials />
                <CalltoAction />
                <Footer />
              </Route>
              <Route exact path="/dashboard">
                <Helmet>
                  <title>
                    Clarity AI Dashboard - Upscale & Enhance your Images
                  </title>
                  <meta
                    name="description"
                    content="Access your Clarity AI dashboard to manage your account and settings. Utilize the leading AI technology for image upscaling and enhancement, all from one central location."
                  />
                  <link rel="canonical" href="https://clarityai.co/dashboard" />
                </Helmet>
                <Dashboard />
              </Route>
              <Route exact path="/api">
                <Helmet>
                  <title>API Image Upscaler & Enhancer - Clarity AI</title>
                  <meta
                    name="description"
                    content="API to upscale & enhance images. Business API to get high resolution images. Benefit from permanent output files, simple parameters, optimized presets, and fast prediction times."
                  />
                  <link rel="canonical" href="https://clarityai.co/api" />
                </Helmet>
                <Docs />
              </Route>
              <Route exact path="/comfyui">
                <Helmet>
                  <title>ComfyUI Upscaler & Enhancer - Clarity AI Plugin</title>
                  <meta
                    name="description"
                    content="The ComfyUI for Clarity AI. Enhance and upscale your images with ease using our AI-driven solutions, optimized for high resolution and perfect results."
                  />
                  <link rel="canonical" href="https://clarityai.co/comfyui" />
                </Helmet>
                <ComfyUI />
              </Route>
              <Route exact path="/pricing">
                <Helmet>
                  <title>Pricing - Clarity AI Image Upscaler & Enhancer</title>
                  <meta
                    name="description"
                    content="Explore Clarity AI's flexible pricing options for our advanced image upscaler and enhancer. Choose from subscription-based plans or one-off purchases to suit your needs."
                  />
                  <link rel="canonical" href="https://clarityai.co/pricing" />
                </Helmet>
                <Pricing />
              </Route>
              <Route exact path="/success">
                <Success />
              </Route>
              <PrivateRoute exact path="/admin">
                <Admin />
              </PrivateRoute>
            </Switch>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </HelmetProvider>
  )
}

export default App
