import React from "react"
import { useAuth } from "../context/AuthContext"
import FAQs from "./Faq"

export default function Pricing() {
  const { user } = useAuth()
  const referrer = sessionStorage.getItem("referrer")

  return (
    <div className="my-24">
      {user?.email && (
        <div>
          <stripe-pricing-table
            data-rewardful
            customer-email={user?.email}
            client-reference-id={referrer}
            pricing-table-id="prctbl_1OPhhMKhV52MrjY6yBFcODWn"
            publishable-key="pk_live_51OP3HQKhV52MrjY6hU7IUmV9HRYKnp8QBgA41f7E2BzO9yFEJSOQ3QenB8bHApfVoHJFj6iryrnalopslAxriOE20017CS8uxp"
          ></stripe-pricing-table>
          <h1 className="my-12 mt-24 text-3xl text-white font-semibold text-center">
            One-time purchase
          </h1>
          <stripe-pricing-table
            data-rewardful
            customer-email={user?.email}
            client-reference-id={referrer}
            pricing-table-id="prctbl_1P9kvLKhV52MrjY6Uw2em3kZ"
            publishable-key="pk_live_51OP3HQKhV52MrjY6hU7IUmV9HRYKnp8QBgA41f7E2BzO9yFEJSOQ3QenB8bHApfVoHJFj6iryrnalopslAxriOE20017CS8uxp"
          ></stripe-pricing-table>
        </div>
      )}
      <FAQs />
    </div>
  )
}
