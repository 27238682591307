import { FaSearch, FaMagic, FaCog } from "react-icons/fa"

export default function Features() {
  return (
    <div className="w-full flex flex-col items-center justify-center mt-32 lg:mt-48 bg-bg-color text-white">
      <div className="w-11/12 2xl:w-10/12 flex flex-col md:gap-16 lg:flex-row">
        <div className="flex flex-col items-center w-full">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-white">
            Features
          </h2>

          <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <div className="space-y-8 lg:grid lg:grid-cols-3 lg:gap-12 lg:space-y-0">
              <div>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 bg-[#1da1f2]">
                  <FaSearch className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-white">
                  Upscale
                </h3>
                <p className="text-gray-500 dark:text-gray-400">
                  Upscale your images from 64px minimum to 13,000px maximum (176
                  megapixels). Clarity AI is the highest-resolution AI upscaler
                  available.
                </p>
              </div>
              <div>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 bg-[#1da1f2]">
                  <FaMagic className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-white">
                  Enhance
                </h3>
                <p className="text-gray-500 dark:text-gray-400">
                  Enhance your images by adding details, removing noise or blur,
                  and fixing artifacts in AI images. Additionally, you can style
                  transfer your images.
                </p>
              </div>
              <div>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 bg-[#1da1f2]">
                  <FaCog className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-white">API</h3>
                <p className="text-gray-500 dark:text-gray-400">
                  Use the API to integrate Clarity AI into your applications.
                  Clarity also offers a ComfyUI plugin.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
