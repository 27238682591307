import React from "react"
import { useAuth } from "../../context/AuthContext"
import { useHistory } from "react-router-dom"
import {
  IconUpscale,
  IconTransfer,
  IconSound,
  IconVideo,
  IconPattern,
  IconImage,
  IconContact,
  IconLogin,
  IconLogout,
} from "../../components/Icons"

export default function Sidebar({ mode, setMode }) {
  const { currentUser, logout, user, signupWithGoogle } = useAuth()
  const history = useHistory()
  const handleLogout = async () => {
    await logout()
    history.push("/")
  }

  async function handleGoogleLogin() {
    const userState = await signupWithGoogle()
    if (userState === "new user") {
      history.push("/pricing")
    } else if (userState === "existing user") {
      history.push("/dashboard")
    } else {
      //telegram("handleGoogleLogin: " + userState)
    }
  }

  return (
    <div className="flex md:flex-col justify-between md:h-screen-16 md:w-20 text-xs font-semibold border-b border-neutral-700 md:border-0">
      <div className="flex md:flex-col">
        {/* <p className="font-bold text-base mt-3 text-center">Mode</p> */}
        <button
          className={`flex flex-col w-14 h-14 sm:w-16 sm:h-16 items-center my-2 ml-2 py-2.5 text-white rounded-lg ${
            mode === "upscale" ? "bg-blue-500" : "hover:bg-gray-700"
          }`}
          onClick={() => setMode("upscale")}
        >
          <IconUpscale />
          <span className="mt-1">Upscale</span>
        </button>

        <button
          className={`w-14 h-14 sm:w-16 sm:h-16 flex flex-col items-center my-2 ml-2 py-2.5 text-white rounded-lg ${
            mode === "style-transfer" ? "bg-blue-500" : "hover:bg-gray-700"
          }`}
          onClick={() => setMode("style-transfer")}
        >
          <IconTransfer />
          <span className="mt-1">Stylize</span>
        </button>

        <button
          className={`w-14 h-14 sm:w-16 sm:h-16 flex flex-col items-center my-2 ml-2 py-2.5 text-white rounded-lg ${
            mode === "video-to-sound" ? "bg-blue-500" : "hover:bg-gray-700"
          }`}
          onClick={() => setMode("video-to-sound")}
        >
          <IconSound />
          <span className="mt-1">Sound</span>
        </button>

        <button
          className={`w-14 h-14 sm:w-16 sm:h-16 flex flex-col items-center my-2 ml-2 py-2.5 text-white rounded-lg ${
            mode === "create" ? "bg-blue-500" : "hover:bg-gray-700"
          }`}
          onClick={() => setMode("create")}
        >
          <IconImage />

          <span className="mt-1">Create</span>
        </button>

        {/* <button
          className={`w-14 h-14 sm:w-16 sm:h-16 flex flex-col items-center my-2 ml-2 py-3 text-white rounded-lg ${
            mode === "pattern-create" ? "bg-blue-500" : "hover:bg-gray-700"
          }`}
          onClick={() => setMode("pattern-create")}
        >
          <IconPattern />
          <span>Pattern</span>
        </button> */}

        {/* <button
          className={`w-14 h-14 sm:w-16 sm:h-16 flex items-center my-2 ml-2 py-2 text-white rounded-lg ${
            mode === "backdropper" ? "bg-blue-500" : "hover:bg-gray-700"
          }`}
          onClick={() => setMode("backdropper")}
        >
          🎧 Backdrop
        </button> */}

        {/* <button
          className={`w-14 h-14 sm:w-16 sm:h-16 flex items-center my-2 ml-2 py-2 text-white rounded-lg ${
            mode === "transform" ? "bg-blue-500" : "hover:bg-gray-700"
          }`}
          onClick={() => setMode("transform")}
        >
          🎧 Transform
        </button> */}

        {/* <button
          className={`w-14 h-14 sm:w-16 sm:h-16 flex items-center my-2 ml-2 py-2 text-white rounded-lg ${
            mode === "moodboard" ? "bg-blue-500" : "hover:bg-gray-700"
          }`}
          onClick={() => setMode("moodboard")}
        >
          🎧 Moodboard
        </button> */}
      </div>
      <div className="flex md:flex-col">
        <div className="w-14 h-14 sm:w-16 sm:h-16 flex flex-col items-center my-2 ml-2 py-2 text-white rounded-lg bg-neutral-800 border border-neutral-700">
          <img
            alt="tokens"
            className="w-4 h-4 sm:w-6 sm:h-6 mb-1"
            src="/credits.png"
          ></img>
          {user ? user.balance : "0"}
        </div>

        <button
          onClick={() =>
            window.open("https://twitter.com/philz1337x", "_blank")
          }
          className="w-14 h-14 sm:w-16 sm:h-16 hidden sm:flex flex-col items-center my-2 ml-2 py-3 rounded-lg hover:bg-gray-700"
        >
          <IconContact />
          <span>Contact</span>
        </button>

        {currentUser ? (
          <button
            className="w-14 h-14 sm:w-16 sm:h-16 flex flex-col items-center my-2 mx-2 py-3 text-white rounded-lg hover:bg-gray-700"
            onClick={handleLogout}
          >
            <IconLogout />
            <div className="mt-1">Logout</div>
          </button>
        ) : (
          <button
            className="w-14 h-14 sm:w-16 sm:h-16 flex flex-col items-center my-2 mx-2 py-3 text-white rounded-lg hover:bg-gray-700"
            onClick={handleGoogleLogin}
          >
            <IconLogin />
            <div className="mt-1">Login</div>
          </button>
        )}
      </div>
    </div>
  )
}
