import React, { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { useHistory } from "react-router-dom"
// import logo from "../../assets/logo.png"

export default function Header() {
  const { currentUser, logout } = useAuth()
  const menuRef = useRef()
  const burgerRef = useRef() // Ref für das Burger-Icon
  const { signupWithGoogle } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (!localStorage.getItem("referrer")) {
      const params = new URLSearchParams(window.location.search)
      const via = params.get("via")
      const ref = params.get("ref")
      const httpReferrer = document.referrer

      if (via) {
        localStorage.setItem("referrer", via)
      } else if (ref) {
        localStorage.setItem("referrer", ref)
      } else if (httpReferrer) {
        localStorage.setItem("referrer", httpReferrer)
      }
    }
  }, [])

  const handleLogout = async () => {
    await logout()
    history.push("/")
  }

  async function handleGoogleLogin() {
    const userState = await signupWithGoogle()
    if (userState === "new user") {
      history.push("/pricing")
    } else if (userState === "existing user") {
      history.push("/dashboard")
    } else {
      //telegram("handleGoogleLogin: " + userState)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (burgerRef.current && burgerRef.current.contains(event.target)) {
        return
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [menuRef])

  return (
    <div className="py-4 w-full h-16 flex justify-between text-white border-b border-neutral-700 bg-black sticky top-0 left-0 px-3 md:px-6">
      <div className="flex items-center">
        <Link to="/" className="flex items-center">
          {/* <img src={logo} alt="Logo" className="h-10 w-10 mr-2" /> */}

          <span className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
            Clarity AI
          </span>
        </Link>
      </div>
      <div>
        <div className="flex">
          <div className="flex items-center md:gap-10 gap-2">
            <Link to="/api">
              <p className="font-bold hidden md:block">API</p>
            </Link>
            <Link to="/comfyui">
              <p className="font-bold hidden md:block">ComfyUI</p>
            </Link>
            {currentUser && (
              <Link to="/pricing">
                <p className="font-bold">Pricing</p>
              </Link>
            )}

            <a href="https://billing.stripe.com/p/login/bIYdTP4ih4Jxfqo9AA">
              <p className="font-bold text-gray-400 hidden md:block">Billing</p>
            </a>

            {currentUser ? (
              <button onClick={handleLogout}>
                <p className="font-bold whitespace-nowrap hidden md:block">
                  Logout
                </p>
              </button>
            ) : (
              <button
                className="font-bold whitespace-nowrap"
                onClick={handleGoogleLogin}
              >
                Log in
              </button>
            )}
            <Link to="/dashboard">
              <button className="w-24 h-8 rounded-lg font-bold text-white bg-gradient-to-r via-blue-500  to-emerald-600 from-sky-400 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate">
                Upscale
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
