import React, { useState } from "react"
import { Helmet } from "react-helmet-async" // Import from react-helmet-async

export const options = [
  {
    question: "How does Clarity AI's upscaling & enhancement work?",
    answer:
      "Using Clarity AI feels like wielding a magic wand! Simply upload any image and Clarity AI will upscale it to a higher resolution, infusing it with as many details as you desire. You'll guide the upscaling with descriptions and controls like 'Creativity', allowing you to dictate the AI's level of detail generation.",
  },
  {
    question: "Is Clarity AI user-friendly for beginners?",
    answer:
      "Clarity AI is crafted to be approachable and easy-to-use for all creators, regardless of experience level. Its intuitive interface, comprehensive tutorials, and supportive community are designed to assist you on your creative path.",
  },
  {
    question: "Who benefits from using Clarity AI's upscaler?",
    answer:
      "Clarity AI caters to a wide range of users from professional photographers and graphic designers to digital artists and enthusiasts seeking enhanced high-resolution images. It's also ideal for AI artists looking to refine AI-generated images, businesses enhancing marketing visuals, and anyone aiming to improve personal photos or create high-quality content.",
  },
  {
    question: "Who developed Clarity AI?",
    answer: "Clarity AI was developed by philz1337x",
  },
  {
    question: "Will images upscaled by Clarity AI contain artifacts?",
    answer:
      "Occasionally, yes. However, artifacts can usually be managed with adjustments to the 'Creativity', 'dynamic', and 'Resemblance' sliders, alongside your descriptive prompts. Artifacts tend to arise primarily when 'Creativity' or 'dynamic' levels are too high, or 'Resemblance' is incorrectly set.",
  },
  {
    question: "Is the payment process secure?",
    answer:
      "Absolutely, our payment processing is 100% secure, managed by Stripe, ensuring that no card details are stored on our end. Stripe's security measures are on par with banking standards.",
  },
  {
    question: "Which payment methods are accepted?",
    answer:
      "We accept various credit or debit cards including VISA, MasterCard, and American Express, along with iDeal, SOFORT, and several others. PayPal and cryptocurrency payments are currently not supported.",
  },
  {
    question: "Can I get a refund?",
    answer:
      "Due to the high costs associated with AI-driven processing, we are unable to offer refunds. The significant GPU processing required for upscaling images incurs substantial costs from our providers, which do not accommodate refunds, making it financially unviable for us to offer refunds.",
  },
  {
    question: "How can I cancel my subscription?",
    answer:
      "You may cancel your subscription at any moment. Simply log in, navigate to 'Billing' at the top of Clarity AI's page, and you'll be directed to Stripe's billing portal where you can manage your subscription, including cancellation.",
  },
  {
    question: "How can I contact Clarity AI's support?",
    answer: (
      <span>
        You can reach out to me via X (formerly Twitter) at{" "}
        <a
          href="https://twitter.com/philz1337x"
          target="_blank"
          rel="noopener noreferrer"
        >
          @philz1337x
        </a>
      </span>
    ),
  },
]

export default function FAQs() {
  const [activeIndex, setActiveIndex] = useState(null)

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: options.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  }

  return (
    <div className="w-full flex flex-col text-white items-center justify-center text-center mt-32">
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>
      <h2 className="w-11/12 2xl:w-10/12 mb-4 text-3xl sm:text-4xl font-extrabold text-white">
        Frequently Asked Questions
      </h2>

      <div className="w-10/12 mt-5 lg:w-9/12 flex flex-col items-center">
        {options.map((item, key) => (
          <div key={key} className="relative inline-block text-left w-full">
            <div
              className={`w-full text-l p-3 px-5 flex items-center text-left justify-between border-b cursor-pointer ${
                activeIndex === key ? "bg-neutral-800" : ""
              }`}
              onClick={() => toggleFAQ(key)}
            >
              <div className="w-full">{item.question}</div>
              <div>{activeIndex === key ? "-" : "+"}</div>
            </div>

            {activeIndex === key && (
              <div className="flex flex-col items-center h-full mb-8">
                <div className="w-full p-5 flex items-center justify-between">
                  {item.answer}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
