import { ComparisonSlider } from "../../components/ComparisonSlider"

export default function Banner() {
  return (
    <div className="w-full flex flex-col items-center justify-center pt-24 xl:mt-24">
      <div className="w-11/12 2xl:w-10/12 items-center flex flex-col text-center">
        <h2 className="mb-4 text-3xl sm:text-4xl font-extrabold text-white mt-12 md:mt-20">
          Unlimited options to enhance your images
        </h2>
        <p className="text-lg font-normal text-white lg:text-xl mb-20">
          Use the latest AI technology to upscale your images, suitable for
          landscapes, portraits, illustrations, anime, interior design, and many
          more.
        </p>

        <h3 className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 text-2xl sm:text-3xl font-bold mb-6">
          Upscale Landscapes
        </h3>
        <ComparisonSlider
          image={{
            outputImage:
              "https://r2.clarityai.co/inputs/mzy66feAaaQ5fdkZzdrobsXM9W63-MpqMhkre7I6n43MAfUns.webp",
            inputImage:
              "https://r2.clarityai.co/inputs/cdef9338-d80b-40ca-8165-75c1c59d06de-3_before-2.webp",
            altOutput: "Upscaled image with enhanced details of a landscape",
            altInput: "Original low-resolution image of a landscape",
          }}
          // showMeta={true}
        />

        <h3 className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 text-2xl sm:text-3xl  mt-20 font-bold mb-6">
          High Resolution Portraits
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <ComparisonSlider
            image={{
              outputImage:
                "https://r2.clarityai.co/inputs/mzy66feAaaQ5fdkZzdrobsXM9W63-gy4zvureAsAvP58hvIlq.webp",
              inputImage:
                "https://r2.clarityai.co/inputs/9c6502b5-96d5-427d-b50b-1f563b0b5308-11_before-2.webp",
              altOutput: "Upscaled and enhanced portrait image",
              altInput: "Original low-resolution image of a woman",
            }}
          />
          <ComparisonSlider
            image={{
              outputImage:
                "https://r2.clarityai.co/inputs/mzy66feAaaQ5fdkZzdrobsXM9W63-23rVuuYYOwCcXdnVKeTG.webp",
              inputImage:
                "https://r2.clarityai.co/inputs/9726cc1c-5168-4930-af6e-a11efac3cf19-7_before-2.webp",
              altOutput: "Upscaled image with enhanced details of a portrait",
              altInput: "Original low-resolution image of a portrait",
            }}
          />
        </div>

        <h3 className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 text-2xl sm:text-3xl  mt-20 font-bold mb-6">
          Upscale from 64 pixels to high resolution
        </h3>

        <ComparisonSlider
          image={{
            outputImage:
              "https://r2.clarityai.co/inputs/adda982a-72b4-4f4d-8f6e-1567c534f058-dog.webp",
            inputImage:
              "https://r2.clarityai.co/inputs/46f9dec4-aa01-4e38-828f-13e5415f53e0-dog_low.webp",
            altOutput: "Upscaled and enhanced dog image",
            altInput:
              "Original very low resolution image of a dog with only 64 x 64 pixels",
          }}
        />

        <h3 className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 text-2xl sm:text-3xl  mt-20 font-bold mb-6">
          Animal images
        </h3>

        <ComparisonSlider
          image={{
            outputImage:
              "https://r2.clarityai.co/inputs/mzy66feAaaQ5fdkZzdrobsXM9W63-dnUb31bTVMaGUxGCWM8f.webp",
            inputImage:
              "https://r2.clarityai.co/inputs/b21dbbd4-1d93-4182-92c4-a380803931fd-fox_1024.webp",
            altOutput: "Enhanced AI image of a fox",
            altInput: "AI image of a fox with 1024 x 1024 pixels",
          }}
        />

        <h3 className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 text-2xl sm:text-3xl mt-20 font-bold mb-6">
          Style Transfer
        </h3>

        <ComparisonSlider
          image={{
            outputImage:
              "https://r2.clarityai.co/inputs/f3693bb2-2341-4e20-a439-dd8bf4acefd0-house_style_after.webp",
            inputImage:
              "https://r2.clarityai.co/inputs/7ce139f1-a3e2-4757-9a7e-90d270db2d11-house_style_before.webp",
            altOutput: "Realistic AI image of a house with a different style",
            altInput: "Original drawing of a house",
          }}
        />
      </div>
    </div>
  )
}
