import { Link } from "react-router-dom"

export default function CalltoAction() {
  return (
    <div className="my-12 w-full flex flex-col items-center">
      <div className="w-11/12 2xl:w-10/12 flex rounded-2xl mt-12 bg-neutral-800">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 ">
          <div className="mx-auto max-w-screen-sm text-center">
            <h2 className="mb-4 text-4xl font-extrabold text-white">
              Convert your AI images into real masterpieces
            </h2>

            <p className="mb-6 font-light text-white md:text-lg">
              Start now to get your AI images with super high resolution and
              intrinsic details.
            </p>
            <Link to="/dashboard">
              <button
                type="submit"
                className="w-44 h-12 flex-none self-center rounded-lg font-bold text-white bg-gradient-to-r via-blue-500  to-emerald-600 from-sky-400 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate"
              >
                <div className="flex flex-row place-content-center">
                  <span>Upscale now</span>
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
