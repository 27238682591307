import React, { useEffect, useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { firestore, serverTimestamp } from "../../firebase"
import { telegram } from "../../utils/telegram"
import { IconInfo } from "../../components/Icons"
import { useHistory } from "react-router-dom"

export default function Create({
  setCreateImageFunction,
  creditCost,
  setCreditCost,
}) {
  const history = useHistory()
  const { user } = useAuth()

  const [error, setError] = useState(false)
  const [prompt, setPrompt] = useState("")
  const [seed, setSeed] = useState("fixed")
  const [quality, setQuality] = useState("high")
  const [aspectRatio, setAspectRatio] = useState("2:3")
  const [seedToolTip, setSeedToolTip] = useState(false)

  const createImageDocument = async () => {
    setError(null)
    if (!user) {
      telegram("Video2Sound.js, createImageDocument, user not found!")
      setError("Register to get tokens.")
      return
    }

    if (user.balance <= 0 || user.balance < creditCost) {
      setError("Not enough tokens!")
      history.push("/pricing")
      return
    }

    const credits = user.balance - creditCost
    try {
      await firestore.collection("users").doc(user.id).update({
        balance: credits,
      })
    } catch (error) {
      telegram("Create, createImageDocument, error: " + error.message)
      setError("Error while updating tokens!")
      return null
    }

    const imageData = {
      userId: user.id,
      createdAt: serverTimestamp(),
      status: "created",
      mode: "create",
      prompt: prompt,
      aspectRatio: aspectRatio,
      quality: quality,
      seedMode: seed,
      creditCost: creditCost,
    }

    try {
      const imagesCollectionRef = firestore
        .collection("users")
        .doc(user.id)
        .collection("images")

      const docRef = await imagesCollectionRef.add(imageData)

      return docRef.id
    } catch (error) {
      telegram("Video2Sound.js, createImageDocument, error: " + error.message)
      return null
    }
  }

  useEffect(() => {
    setCreateImageFunction(
      () => () => createImageDocument(user, creditCost, history, setError)
    )
  }, [
    user,
    creditCost,
    history,
    setError,
    setCreateImageFunction,
    prompt,
    aspectRatio,
    quality,
    seed,
  ])

  useEffect(() => {
    if (quality === "low") {
      setCreditCost(1)
    }
    if (quality === "high") {
      setCreditCost(5)
    }
  }, [prompt, setCreditCost, quality])

  return (
    <div className="w-full pl-3 pr-1 py-2 pb-3 relative md:h-[calc(100vh-138px)] md:overflow-y-auto md:max-w-xs md:pr-3 border-neutral-700">
      <p className="font-bold mt-1 mb-2">Prompt</p>
      <textarea
        id="prompt"
        type="text"
        rows="3"
        className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />

      <div>
        <div className="flex items-center mt-4">
          <p className="font-bold my-2 mr-2">Aspect ratio</p>
        </div>
        <select
          className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
          value={aspectRatio}
          onChange={(event) => setAspectRatio(event.target.value)}
        >
          <option value="21:9">21:9 (horizontal)</option>
          <option value="16:9">16:9 </option>
          <option value="3:2">3:2</option>
          <option value="5:4">5:4</option>
          <option value="1:1">1:1 (square)</option>
          <option value="4:5">4:5 </option>
          <option value="2:3">2:3 </option>
          <option value="9:16">9:16 </option>
          <option value="9:21">9:21 (vertical)</option>
        </select>
      </div>

      <div>
        <div className="flex items-center mt-4">
          <p className="font-bold my-2 mr-2">Quality</p>
        </div>
        <select
          className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
          value={quality}
          onChange={(event) => setQuality(event.target.value)}
        >
          <option value="high">High</option>
          <option value="low">Low (but fast & cheap)</option>
        </select>
      </div>

      <div>
        <div className="flex items-center mt-4">
          <p className="font-bold my-2 mr-2">Seed</p>
          <div
            className="cursor-pointer"
            onMouseEnter={() => setSeedToolTip(true)}
            onMouseLeave={() => setSeedToolTip(false)}
          >
            <IconInfo />
          </div>
        </div>
        {seedToolTip && (
          <div className="absolute z-10 w-52 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
            Decide if you want to get a reproducible sound or a random one. With
            random, you can create unlimited sounds for one prompt.
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>
        )}

        <select
          className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
          value={seed}
          onChange={(event) => setSeed(event.target.value)}
        >
          <option value="fixed">Fixed</option>
          <option value="random">Random</option>
        </select>
      </div>

      {prompt && (
        <div className="flex flex-col items-center justify-center my-4 py-1 bg-neutral-800 border border-neutral-700 rounded-lg text-sm text-neutral-400">
          <div className="text-center">
            {creditCost && <p>Will cost {creditCost} tokens</p>}
          </div>
        </div>
      )}

      {error && (
        <div
          className="flex items-center p-4 mb-4 text-sm border rounded-lg bg-gray-800 text-red-400 border-red-800"
          role="alert"
        >
          <div> {error}</div>
        </div>
      )}
    </div>
  )
}
