import React, { useState, useEffect } from "react"
import { auth, GoogleAuthProvider, firestore } from "../firebase"

import { telegram } from "../utils/telegram"

const AuthContext = React.createContext()

export function useAuth() {
  return React.useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [images, setImages] = useState(null)

  async function configUser(user) {
    // const configRef = firestore.collection("config").doc("default")
    // const configDoc = await configRef.get()

    // let free_balance = 8

    // if (configDoc.exists) {
    //   const configData = configDoc.data()
    //   free_balance =
    //     configData.freeBalance !== undefined
    //       ? configData.freeBalance
    //       : free_balance
    // }

    const userRef = firestore.collection("users").doc(user.uid)

    const createdAt = new Date()

    const referrer = sessionStorage.getItem("referrer")

    await userRef.set({
      email: user.email,
      balance: 0,
      createdAt: createdAt,
      referrer: referrer,
      plan: "free",
    })

    //telegram("New user: " + user.email + ", got balance: " + free_balance)
    //spammt mich ständig zu mit new user
  }

  async function signupWithEmail(email, password) {
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      )
      const user = userCredential.user
      await configUser(user)
      return "success"
    } catch (error) {
      telegram(
        "frontend, AuthContext, signupWithEmail, error: " + error.message
      )
      throw new Error("Failed to create an account")
    }
  }

  async function signupWithGoogle() {
    try {
      const provider = new GoogleAuthProvider()
      const userCredential = await auth.signInWithPopup(provider)
      const user = userCredential.user
      const isNewUser = userCredential.additionalUserInfo?.isNewUser

      if (isNewUser) {
        await configUser(user)
        return "new user"
      } else {
        return "existing user"
      }
    } catch (error) {
      // telegram(
      //   "frontend, AuthContext, signupWithGoogle, error: " + error.message
      // )
      // spammt mich ständig zu mit pop up error
    }
  }

  function login(email, password) {
    try {
      telegram("User login: " + email)
      return auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      telegram("frontend, AuthContext, login, error: " + error.message)
    }
  }

  const logout = async () => {
    await auth.signOut()
    setCurrentUser(null)
    setUser(null)
    setImages(null)
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const usersRef = firestore.collection("users").doc(currentUser.uid)

        const unsubscribe = usersRef.onSnapshot(async (snapshot) => {
          if (snapshot.exists) {
            const userData = snapshot.data()
            const userId = snapshot.id
            const paymentsRef = usersRef.collection("payments")
            const collectionsPayments = await paymentsRef.get()
            const collectionsData = collectionsPayments.docs.map((doc) =>
              doc.data()
            )
            const sumOfCredits = collectionsData
              .filter((item) => item.status === "succeeded")
              .flatMap((item) => item.items)
              .map((item) => parseInt(item.price.metadata.credits))
              .filter((credits) => !isNaN(credits))
              .reduce((accumulator, current) => accumulator + current, 0)

            setUser({ id: userId, ...userData, credits_bought: sumOfCredits })
          }
        })

        return () => unsubscribe()
      } catch (error) {
        telegram("frontend, AuthContext, fetchUser, error: " + error.message)
      }
    }

    if (currentUser) {
      fetchUser()
    }
  }, [currentUser])

  useEffect(() => {
    const fetchImages = async () => {
      try {
        if (!currentUser) {
          return
        }

        const imagesRef = firestore
          .collection("users")
          .doc(currentUser.uid)
          .collection("images")
          .orderBy("createdAt", "desc")
          .limit(200)

        const unsubscribe_images = imagesRef.onSnapshot(
          async (querySnapshot) => {
            const imagesData = []

            querySnapshot.forEach((doc) => {
              const imageData = doc.data()
              const imageId = doc.id
              imagesData.push({ id: imageId, ...imageData })
            })

            setImages(imagesData)
          }
        )

        return () => unsubscribe_images()
      } catch (error) {
        telegram("frontend, AuthContext, fetchImages, error: " + error.message)
      }
    }

    if (currentUser) {
      fetchImages()
    }
  }, [currentUser])

  const postsRef = firestore.collection("posts")
  const postsQuery = postsRef.orderBy("createdAt")
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const snapshot = await postsQuery.get()
        const postsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))

        setPosts(postsData)
        setLoading(false)
      } catch (error) {
        telegram("frontend, AuthContext, fetchPosts, error: " + error.message)
      }
    }

    fetchPosts()
  }, [currentUser])

  const value = {
    currentUser,
    signupWithGoogle,
    signupWithEmail,
    login,
    logout,
    resetPassword,
    user,
    images,
    posts,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
