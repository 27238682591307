import React from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { useHistory } from "react-router-dom"

export default function Footer() {
  const { currentUser, logout } = useAuth()
  const history = useHistory()
  const { signupWithGoogle } = useAuth()

  const handleLogout = async () => {
    await logout()
    history.push("/")
  }

  async function handleGoogleLogin() {
    const userState = await signupWithGoogle()
    if (userState === "new user") {
      history.push("/pricing")
    } else if (userState === "existing user") {
      history.push("/dashboard")
    } else {
      //telegram("handleGoogleLogin: " + userState)
    }
  }

  return (
    <div className="my-12 w-full flex flex-col items-center">
      <div className="w-11/12 2xl:w-10/12 mt-12 ">
        <div className="mx-auto w-full">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0 ">
              <Link to="/" className="flex items-center">
                {/* <img src={logo} alt="Logo" className="h-10 w-10 mr-2" /> */}

                <span className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                  Clarity AI
                </span>
              </Link>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6">
              <div>
                <h2 className="mb-6 text-sm font-semibold uppercase text-white">
                  LINKS
                </h2>
                <ul className="text-gray-400 font-medium">
                  <li className="mb-4">
                    <Link to="/dashboard">Upscale</Link>
                  </li>

                  <li className="mb-4">
                    <a href="https://billing.stripe.com/p/login/bIYdTP4ih4Jxfqo9AA">
                      Billing
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="https://x.com/philz1337x">Contact</a>
                  </li>
                  <li className="mb-4">
                    {currentUser ? (
                      <button onClick={handleLogout}>Logout</button>
                    ) : (
                      <button onClick={handleGoogleLogin}>Log in</button>
                    )}
                  </li>
                  <li className="mb-4">
                    {currentUser && <Link to="/pricing">Pricing</Link>}
                  </li>

                  <li className="mb-4">
                    <a href="https://clarity-ai.getrewardful.com/">
                      Affiliate - Earn up to $449 per sale
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  MORE
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <Link to="/api">Clarity API</Link>
                  </li>
                  <li className="mb-4">
                    <Link to="/comfyui">Upscale in ComfyUI</Link>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://interiorai.com/?ref=clarityai"
                      className="hover:underline"
                    >
                      Interior AI
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wallpaperai.co/?ref=clarityai"
                      className="hover:underline"
                    >
                      Wallpaper AI
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-700 sm:mx-auto lg:my-8" />
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              © 2024 Clarity AI . All Rights Reserved.
            </span>
            <div className="flex mt-4 sm:justify-center sm:mt-0">
              <a
                href="https://x.com/philz1337x"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 17"
                >
                  <path
                    fillRule="evenodd"
                    d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Twitter page</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
